import React from 'react';

import SecondaryNavMenuItems from '@/components/layouts/header/secondary-nav-menu-items';
import SecondaryNavMenuLink from '@/components/layouts/header/secondary-nav-menu-link';
import { ButtonIcon } from '@/components/shared/buttons/button-icon';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { styled } from '@/stitches.config';
import { IMenu } from '@/types/layout/menu';

const SecondaryNavStyled = styled('div', {
  '@lg': {
    position: 'relative',
    backgroundColor: '$gray800',
    height: 'var(--secondary-nav-height)',
  },
  '@maxlg': {
    position: 'absolute',
    right: '$space-0',
    top: '$space-0',
    width: '100%',
    height: 'var(--viewport-height)',
    paddingTop: 'var(--header-height)',
    pointerEvents: 'none',
    opacity: '0',
    transform: 'translateY(-40px)',
    transitionProperty: 'opacity, transform',
    transitionDuration: 'var(--transition-duration)',
    transitionTimingFunction: 'var(--transition-easing)',
    '& > *': {
      height: '100%',
    },
    '&.is-buying-tools-open': {
      pointerEvents: 'all',
      opacity: '1',
      transform: 'translateY(0)',
    },
  },
  variants: {
    isTransparentNavigation: {
      true: {
        '@lg': {
          display: 'none',
        },
        '@maxlg': {
          '&.is-buying-tools-open': {
            transform: 'translateY($space-2)',
          },
        },
      },
    },
  },
});

const SecondaryNavInner = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  columnGap: '$space-6',
  '@lg': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '@maxlg': {
    height: '100%',
    overflow: 'hidden auto',
    '-webkit-overflow-scrolling': 'touch',
  },
});

interface SecondaryNavProps
  extends React.ComponentProps<typeof SecondaryNavStyled> {
  menuBuyingTool?: IMenu[];
  menuModel?: IMenu[];
  onCloseBuyingTools?: () => void;
  toggleBuyingTools: boolean;
  buyingToolsRef?: React.Ref<HTMLDivElement> | undefined;
  isTransparentNavigation?: boolean;
}

const SecondaryNav: React.FC<SecondaryNavProps> = ({
  menuBuyingTool,
  menuModel,
  onCloseBuyingTools,
  toggleBuyingTools,
  buyingToolsRef,
  isTransparentNavigation,
  ...rest
}: SecondaryNavProps) => {
  return (
    <SecondaryNavStyled
      isTransparentNavigation={isTransparentNavigation}
      data-test="secondary_nav"
      {...rest}
    >
      <Container size="desktop-only">
        <SecondaryNavInner ref={buyingToolsRef}>
          <SecondaryNavMenuItems
            css={{
              '@maxlg': {
                display: 'none',
              },
            }}
          >
            {menuModel.map((o, index) => (
              <SecondaryNavMenuLink key={index} {...o} />
            ))}
          </SecondaryNavMenuItems>
          <SecondaryNavMenuItems>
            {menuBuyingTool.map((o, index) => (
              <SecondaryNavMenuLink key={index} {...o} />
            ))}
          </SecondaryNavMenuItems>
          {!!toggleBuyingTools && (
            <Box
              cursor="pointer"
              paddingY="4"
              textAlign="center"
              height="full"
              display={{ '@lg': 'none' }}
              onClick={onCloseBuyingTools}
            >
              <ButtonIcon
                icon="close"
                variant="button-icon-color-white"
                size="lg"
                test={{
                  dataTest: 'close_button',
                }}
              />
            </Box>
          )}
        </SecondaryNavInner>
      </Container>
    </SecondaryNavStyled>
  );
};

SecondaryNav.displayName = 'SecondaryNav';

export default SecondaryNav;
