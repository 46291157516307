import { useEffect, useRef, useState, useTransition } from 'react';

export const disposables = () => {
  const _disposables: (() => void)[] = [];

  const api = {
    add(cb: () => void) {
      // Ensure we don't add the same callback twice
      if (!_disposables.includes(cb)) {
        _disposables.push(cb);
      }
      return () => {
        const idx = _disposables.indexOf(cb);
        if (idx >= 0) {
          for (const dispose of _disposables.splice(idx, 1)) {
            dispose();
          }
        }
      };
    },
    dispose() {
      for (const dispose of _disposables.splice(0)) {
        dispose();
      }
    },
    setTimeout(...args: Parameters<typeof setTimeout>) {
      const timer = setTimeout(...args);
      return api.add(() => clearTimeout(timer));
    },
    requestAnimationFrame(...args: Parameters<typeof requestAnimationFrame>) {
      const raf = requestAnimationFrame(...args);
      return api.add(() => cancelAnimationFrame(raf));
    },
    nextFrame(...args: Parameters<typeof requestAnimationFrame>) {
      return api.requestAnimationFrame(() => {
        return api.requestAnimationFrame(...args);
      });
    },
  };

  return api;
};

type MountTransitionStageType = 'from' | 'enter' | 'leave';

export const useMountTransition = ({
  isMounted,
  unmountDelay,
}: {
  isMounted: boolean;
  unmountDelay?: number;
}) => {
  const [isPendingTransition, startTransition] = useTransition();
  const [stage, setStage] = useState<MountTransitionStageType>(
    isMounted ? 'enter' : 'from'
  );
  const [shouldMount, setShouldMount] = useState<boolean>(isMounted);
  const d = useRef(disposables());

  useEffect(() => {
    if (isPendingTransition) return;

    d.current.dispose();
    if (isMounted) {
      setStage('from');
      setShouldMount(true);
      d.current.nextFrame(() => {
        setStage('enter');
      });
    } else {
      setStage('leave');
      d.current.setTimeout(() => {
        setShouldMount(false);
      }, unmountDelay);
    }
    return d.current.dispose;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  return {
    stage,
    shouldMount,
    trueFalseTransitionState: stage === 'enter',
    startTransition,
  };
};
